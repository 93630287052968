import React, { useState } from "react";
// import { useNavigate } from 'react-router-dom';
import BlogItem from "./BlogItem";
import { Link, navigate } from "gatsby";

const BlogSectionWithSearch = ({ data }) => {
    const [search, setSearch] = useState('')
    const searchBlog = () => {
        // redirect to /all with param search
        if (search.trim()) {
            // Redirect to the /all page with a search query parameter
            // navigate(`/blog/all/?search=${encodeURIComponent(search)}`);
            window.location.href = `/blog/all/?search=${encodeURIComponent(search)}`;
        } else {
            return;
        }
    }
    return (
        <>
            <div className="max-w-7xl mx-auto px-4 md:py-12 py-6">
                <div className="flex md:flex-row flex-col gap-4 justify-between items-center">
                    <h2 className="font1 text-5xl text-[#384051] tracking-tight">Economize Recommends</h2>
                    <div className="bg-white inline-flex items-center py-1 pl-4 pr-1 rounded-full max-w-5xl border border-[#d0d5dd]">
                        <input type="email" value={search} placeholder="Search for a blog here..." onChange={(e) => setSearch(e.target.value)}
                            onKeyDownCapture={(e) => {
                                if (e.key === 'Enter') {
                                    searchBlog()
                                }
                            }
                            }
                            className="w-full  bg-transparent  px-2 text-[#6c7180] text-base border-none outline-none" />
                        <button aria-label="Search" onClick={searchBlog} className="text-white py-3 px-4 rounded-full focus:outline-none">
                            <svg clipRule="evenodd" fillRule="evenodd" width="24" height="24" fill="#d0d5dd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fillRule="nonzero" /></svg>
                        </button>
                    </div>
                </div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 my-10">
                    {data && data?.edges && data?.edges.map(({ node }) => <BlogItem key={node?.id} data={node} />)}
                </div>
                <div className="flex justify-center mx-auto w-full">
                    <Link to="/all" className="bg-[#f0f4ff] no-underline capitalize sm:text-2xl relative text-xl text-[#004cff] border-2 border-[#004cff] rounded-full py-2.5 px-8">view more</Link>
                </div>

            </div>
        </>
    )
}
export default BlogSectionWithSearch