import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import AuthorInfo from "./AuthorInfo"

const FeaturedPostSection = ({ data }) => {
    const { title, excerpt, slug, author, featuredImage } = data
    return (
        <div className="w-full md:py-6 py-8">
            <div className="max-w-7xl mx-auto px-4">
                <div className="border border-[#D0D5DD] rounded-xl md:p-6 py-4 px-4 grid lg:grid-cols-2 grid-cols-1 md:flex-row flex-col gap-4">
                    <div className="flex flex-col gap-0">
                        <div className="w-full">
                            <span className="text-[#854D0E] text-sm py-1 px-5 bg-[#FACC15] rounded-full inline-block font2 mb-4 font-medium">Featured Blog</span>
                            <h2 className="md:mb-3 mb-1"><Link to={`/${slug}`} className="no-underline sm:text-[36px] text-[1.6rem]  leading-9 sm:leading-[40px] text-[#202837] font1 tracking-tight mb-4">{title}</Link></h2>
                        </div>
                        <div className="w-full">
                            <div className="md:h-[5.77rem] h-[7rem] md:mb-1 mb-0 md:block hidden md:visible invisible overflow-hidden sm:text-xl text-lg  md:leading-8 tracking-tight text-[#4C5463]" dangerouslySetInnerHTML={{ __html: excerpt }} />
                            {author && author?.node && <AuthorInfo author={author} />}
                            <Link to={`/${slug}`} className="text-[#004CFF] no-underline font-semibold flex items-center gap-2">Read Now
                                <svg width={24} height={24} fill="#004CFF" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Link to={`/${slug}`}>
                        <GatsbyImage
                            image={getImage(featuredImage.node)}
                            alt={title}
                            loading="eager"
                            priority="true" 
                            width={600}
                            height={340}
                            className="w-full" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FeaturedPostSection